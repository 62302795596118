import { useEffect } from "react";
import landmarkData from "./data/landmarks.json";
import cityData from "./data/cities.json";
import tubelinesData from "./data/tubelines.json";

// Import aframe components
import "./aframe-components/label";
import "./aframe-components/simple-label";

// const AFRAME = global.AFRAME;
// const THREE = global.THREE;

function ARScene() {
  const init = async () => {
    const generateLabel = (data) => {
      const root = document.createElement("a-entity");
      const root1 = document.createElement("a-entity");
      const root2 = document.createElement("a-entity");

      root.appendChild(root1);
      root1.appendChild(root2);

      root.id = data.id;
      root.setAttribute("rotation", data.rotation);

      root1.setAttribute("depth", data.depth);
      root1.setAttribute("height", data.height);
      root1.setAttribute("width", data.width);
      root1.setAttribute("position", data.position);
      root1.setAttribute("look-at", "[camera]");

      root2.setAttribute("label", data.label);
      root2.setAttribute("look-at", "0 0 1");

      return root;
    };

    const generateSimpleLabel = (data) => {
      const root = document.createElement("a-entity");
      const root1 = document.createElement("a-entity");
      const root2 = document.createElement("a-entity");

      root.appendChild(root1);
      root1.appendChild(root2);

      root.id = data.id;
      root.setAttribute("rotation", data.rotation);

      root1.setAttribute("depth", data.depth);
      root1.setAttribute("height", data.height);
      root1.setAttribute("width", data.width);
      root1.setAttribute("position", data.position);
      // root1.setAttribute("look-at", "[camera]");
      root1.setAttribute("rotation", {x: 0, y: data.signRotation, z: 0})

      root2.setAttribute("simple-label", data.label);
      root2.setAttribute("look-at", "0 0 1");

      return root;
    };

    const landmarkOffset = document.getElementById("landmarksOffset");
    const citiesOffset = document.getElementById("citiesOffset");
    const tubelinesOffset = document.getElementById("tubelinesOffset");

    // Generate landmarks
    landmarkData.forEach((entry) => {
      const element = generateLabel(entry);
      landmarkOffset.appendChild(element);
    });

    // Generate Cities
    cityData.forEach((entry) => {
      const element = generateLabel(entry);
      citiesOffset.appendChild(element);
    });

    // // Generate tubelines
    tubelinesData.forEach((entry) => {
      const element = generateSimpleLabel(entry);
      tubelinesOffset.appendChild(element);
    });
  };

  useEffect(() => {
    document.getElementById("arscene").innerHTML = `
            <a-scene
                device-orientation-permission-ui="enabled: false"
                arjs="sourceType: webcam;"
                embedded
                renderer="
                    antialias: true;
                    logarithmicDepthBuffer: true;
                    sortObjects: true;
                "
                stats="false"
                vr-mode-ui="enabled: false"
            >
        
            <!-- Assets -->
            <a-assets timeout="20000">
                <a-asset-item id="undergroundMap" src="assets/models/undergroundMap7.glb"></a-asset-item>
                <img src="assets/images/landmarks/British_Museum.jpg"></img>
                <img src="assets/images/landmarks/Brockwell_Park.jpg"></img>
                <img src="assets/images/landmarks/Buckingham_Palace.jpg"></img>
                <img src="assets/images/landmarks/Centre_Court_Wimbledon.jpg"></img>
                <img src="assets/images/landmarks/Chelsea_Bridge.jpg"></img>
                <img src="assets/images/landmarks/Chislehurst_Caves.jpg"></img>
                <img src="assets/images/landmarks/Clapham_Common.jpg"></img>
                <img src="assets/images/landmarks/Crystal_Palace_Park.jpg"></img>
                <img src="assets/images/landmarks/Eltham_Palace.jpg"></img>
                <img src="assets/images/landmarks/Epsom_Downs_Racecourse.jpg"></img>
                <img src="assets/images/landmarks/Hampton_Court_Palace.jpg"></img>
                <img src="assets/images/landmarks/Heathrow_Airport.jpg"></img>
                <img src="assets/images/landmarks/Horniman_Museuem.jpg"></img>
                <img src="assets/images/landmarks/Houses_of_Parliament.jpg"></img>
                <img src="assets/images/landmarks/Hyde_Park.jpg"></img>
                <img src="assets/images/landmarks/Kensington_Palace.jpg"></img>
                <img src="assets/images/landmarks/Kew_Gardens.jpg"></img>
                <img src="assets/images/landmarks/London_Eye.jpg"></img>
                <img src="assets/images/landmarks/Lots_Road_Power_Station.jpg"></img>
                <img src="assets/images/landmarks/M16_Building.jpg"></img>
                <img src="assets/images/landmarks/Millennium_Dome.jpg"></img>
                <img src="assets/images/landmarks/Notting_Hill.jpg"></img>
                <img src="assets/images/landmarks/Royal_Albert_Hall.jpg"></img>
                <img src="assets/images/landmarks/Royal_Hospital_Chelsea.jpg"></img>
                <img src="assets/images/landmarks/Royal_Observatory.jpg"></img>
                <img src="assets/images/landmarks/St_Pauls_Cathedral.jpg"></img>
                <img src="assets/images/landmarks/Tate_Modern.jpg"></img>
                <img src="assets/images/landmarks/The_BT_Tower.jpg"></img>
                <img src="assets/images/landmarks/The_Gherkin.jpg"></img>
                <img src="assets/images/landmarks/The_Oval_Cricket_Ground.jpg"></img>
                <img src="assets/images/landmarks/The_Shard.jpg"></img>
                <img src="assets/images/landmarks/The_Tower_St George_Wharf.jpg"></img>
                <img src="assets/images/landmarks/The_Walkie_Talkie.jpg"></img>
                <img src="assets/images/landmarks/Trellick_Tower.jpg"></img>
                <img src="assets/images/landmarks/US_Embassy.jpg"></img>
                <img src="assets/images/landmarks/Wembley_Stadium.jpg"></img>
                <img src="assets/images/landmarks/Westminster_Abbey.jpg"></img>
            </a-assets>
        
            <!-- Camera todo- is Camera entity named correctly?-->
            <a-camera
                renderer="
                logarithmicDepthBuffer: false;
                colorManagement: true;
                sortObjects: false;"
                collider-check
                look-controls="touchEnabled: false"
                gps-camera='
                    simulateLatitude: 51.48194567172135;
                    simulateLongitude: -0.14452152670600438;
                    simulateAltitude: 110.0;
                '
                id="camera"
                rotation-reader
            >
                </a-camera>
       
                <a-entity position="0 -0.9 0" raycaster="objects: .collidable;" rotation="90 0 0"></a-entity>
            </a-entity>
        
            <!-- Entities -->
            <a-entity id="landmarksOffset" position="0 0 0" scale="1.0 1.0 1.0" visible="true" gps-entity-place="latitude: 51.48193567172135; longitude: -0.14452152670600438;">
            </a-entity>
        
            <a-entity id="citiesOffset" position="0 0 0" scale="1.0 1.0 1.0" visible="false">
            </a-entity>
        
            <a-entity id="undergroundOffset"   position="0 -21.8 0" scale="10.0 10.0 10.0" visible="false">
                <a-entity id="undergroundMap" gltf-model="#undergroundMap" animation-mixer="timeScale: 1; loop: repeat"></a-entity>
            </a-entity>
            
            <a-entity id="tubelinesOffset" position="0 0 0"  scale="1.0 1.0 1.0" visible="false">
             </a-entity>       
      
        </a-scene>
    `;

    init();

    return () => (document.getElementById("ARSCENE").innerHTML = "");
  }, []);

  return <></>;
}

export default ARScene;
