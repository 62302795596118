import $ from "jquery";

export default function NavMenu() {
  const disableAllButtons = () => {
    const refs = [
      $("#landmarks-button"),
      $("#cities-button"),
      $("#underground-button"),
    ];
    refs.forEach((button) => {
      button.removeClass("nav-button-active");
    });
  };

  const handleClickLandmarks = () => {
    disableAllButtons();
    $("#landmarks-button").addClass("nav-button-active");
    $("#landmarksOffset").attr("visible", true);
    $("#citiesOffset").attr("visible", false);
    $("#undergroundOffset").attr("visible", false);
    $("#tubelinesOffset").attr("visible", false);
    $("#nav-menu-container").css({ transform: "translateX(0%)" });
  };

  const handleClickCities = () => {
    disableAllButtons();
    $("#cities-button").addClass("nav-button-active");
    $("#landmarksOffset").attr("visible", false);
    $("#citiesOffset").attr("visible", true);
    $("#undergroundOffset").attr("visible", false);
    $("#tubelinesOffset").attr("visible", false);
    $("#nav-menu-container").css({ transform: "translateX(-33%)" });
  };

  const handleClickUnderground = () => {
    disableAllButtons();
    $("#underground-button").addClass("nav-button-active");
    $("#landmarksOffset").attr("visible", false);
    $("#citiesOffset").attr("visible", false);
    $("#undergroundOffset").attr("visible", true);
    $("#tubelinesOffset").attr("visible", true);
    $("#nav-menu-container").css({ transform: "translateX(-66%)" });
  };

  return (
    <div className="nav-menu-root">
      <div className="nav-menu-container" id="nav-menu-container">
        <a
          className="nav-button nav-button-landmarks nav-button-active"
          id="landmarks-button"
          onClick={handleClickLandmarks}
        >
          <img
            height="35"
            src="assets/icons/landmarks-icon.svg"
            width="35"
            alt=""
          />
        </a>
        <a
          className="nav-button nav-button-cities"
          id="cities-button"
          onClick={handleClickCities}
        >
          <img
            height="35"
            src="assets/icons/compass-icon.svg"
            width="35"
            alt=""
          />
        </a>
        <a
          className="nav-button nav-button-tubes"
          id="underground-button"
          onClick={handleClickUnderground}
        >
          <img
            height="35"
            src="assets/icons/underground-icon.svg"
            width="35"
            alt=""
          />
        </a>
      </div>
    </div>
  );
}
