import "./App.css";
import { useEffect, useState } from "react";
import ARScene from "./ARScene";
import requestPermissions from "./Permissions";
import NavMenu from "./components/NavMenu";

function App() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showScene, setShowScene] = useState(false);

  const [registeredLoadingCallback, setRegisteredLoadingCallback] =
    useState(false);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }, []);

  useEffect(() => {
    if (showScene && !registeredLoadingCallback) {
      setRegisteredLoadingCallback(true);

      const scene = document.querySelector("a-assets");
      scene.addEventListener("loaded", function (e) {
        window.loading = false;
        console.log("loaded!!!!");
        document.getElementById("loading-screen").style.display = "none";
        document.getElementById("hint-screen").style.display = "flex";
      });
    }
  }, [showScene]);

  const onCompleteOnboarding = () => {
    window.onboardingComplete = true;
    document.getElementById("hint-screen").style.display = "none";
  };

  const onPermissionsGranted = () => {
    document.getElementById("welcome-screen").style.display = "none";
    document.getElementById("loading-screen").style.display = "flex";
    setShowScene(true);
    setLoading(false);
  };

  const onClickAcceptPermissions = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    requestPermissions()
      .then(onPermissionsGranted)
      .catch((e) => {
        setErrorMsg(`Failed to get ${e} permissions. Please try again.`);
        setLoading(false);
        throw e;
      });
  };

  const onToggleDebugMode = () => {
    const object = document.getElementById("debugEntity").object3D;
    object.visible = !object.visible;
  };

  return (
    <>
      <div id="arscene" className="arscene" />

      {showScene && (
        <>
          <ARScene />
          <NavMenu />
          {/*<button className='debug-button' onClick={onToggleDebugMode}>Debug toggle</button>*/}
        </>
      )}

      <div className="hint-screen" id="hint-screen">
        <div className="centered-flex">
          <div id="move-hint" className="hint-container">
            <img src="assets/images/rotate-device.svg" width="100px" />
            <p className="hint-text">
              Move your phone around to explore the view.
            </p>
            <button className="hint-next-button" onClick={onCompleteOnboarding}>
              <img src="/images/chevron_right.svg" width="30px" alt="" />
            </button>
          </div>
        </div>
      </div>

      <div className="splash-screen" id="loading-screen">
        <h1 className="splash-title">ILLUMINATING LONDON AR</h1>
        <h5>Loading...</h5>
        <span className="linear-progress-root">
          <span className="linear-progress-bar1" />
          <span className="linear-progress-bar2" />
        </span>
      </div>

      <div className="splash-screen" id="welcome-screen">
        <h1 className="splash-title">ILLUMINATING LONDON AR</h1>
        <p className="splash-text">
          For the AR to work, please give us access to your camera, motion and
          orientation sensors.
        </p>
        <div>
          <button className="splash-button" onClick={onClickAcceptPermissions}>
            Continue
          </button>
        </div>
        <p className="error-message">{errorMsg}</p>
      </div>

      <div className="hint-screen landscape-hint">
        <div className="centered-flex">
          <div id="rotate-hint" className="hint-container">
            <img
              src="assets/images/rotate-device-2.svg"
              width="100px"
              height="100px"
              alt=""
            />
            <p className="hint-text">Please rotate your phone</p>
            <div />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
