import { mergeBufferGeometries } from "../utils/BufferGeometryUtils.js";

const AFRAME = global.AFRAME;
const THREE = global.THREE;

/* global AFRAME */
if (typeof AFRAME === "undefined") {
  throw new Error(
    "Component attempted to register before AFRAME was available."
  );
}

AFRAME.registerComponent("simple-label", {
  schema: {
    height: { default: 1, min: 0 }, //the label
    width: { default: 1, min: 0 }, //the label
    text: { default: "Hello world!" }, //the label
    fontSize: { default: 1.3, min: 0 },
    textColor: { default: "black" },
    color: { default: "white" },
    labelRotation: { default: 0 }, //if label is to be below horizon
  },

  init: function () {
    var data = this.data;

    this.labelMat = new THREE.MeshBasicMaterial({
      //the label
      color: this.data.color,
      side: THREE.DoubleSide,
      // transparent: true,
      // opacity: 0.9
    });

    this.labelMeshObject = document.createElement("a-entity");
    this.el.appendChild(this.labelMeshObject);

    // troika-text
    this.labelTextObject = document.createElement("a-entity");
    this.labelMeshObject.appendChild(this.labelTextObject);

    this.regenerate();
  },
  generateLabelGeometry: function () {
    const width = this.data.width;
    const height = this.data.height;

    const labelLeftGeometry = new THREE.CircleGeometry(
      height / 2,
      32,
      Math.PI / 2,
      Math.PI
    );
    const leftMat = new THREE.Matrix4();
    leftMat.setPosition(-width / 2, 0, 0);
    labelLeftGeometry.applyMatrix4(leftMat);

    const labelRightGeometry = new THREE.CircleGeometry(
      height / 2,
      32,
      -Math.PI / 2,
      Math.PI
    );
    const rightMat = new THREE.Matrix4();
    rightMat.setPosition(width / 2, 0, 0);
    labelRightGeometry.applyMatrix4(rightMat);

    const labelCenterGeometry = new THREE.PlaneGeometry(width, height);

    const labelGeometry = mergeBufferGeometries([
      labelLeftGeometry,
      labelCenterGeometry,
      labelRightGeometry,
    ]);
    const labelGeometryMatrix = new THREE.Matrix4();

    labelGeometryMatrix.setPosition(0, height / 2, 0);
    labelGeometry.applyMatrix4(labelGeometryMatrix);
    return labelGeometry;
  },
  regenerate: function () {
    const text = this.data.text;
    const width = this.data.width;
    const height = this.data.height;

    // Label part
    const labelGeometry = this.generateLabelGeometry();
    const childMesh = new THREE.Mesh(labelGeometry, this.labelMat);
    this.labelMeshObject.setObject3D("mesh", childMesh);
    this.labelMeshObject.setAttribute(
      "rotation",
      `0 0 ${this.data.labelRotation}`
    );

    // Update text
    this.labelTextObject.setAttribute(
      "troika-text",
      `font: assets/fonts/conduitITCmediumRegularFont.ttf; fontSize: ${this.data.fontSize}; color: ${this.data.textColor}; value: ${text}`
    );
    this.labelTextObject.setAttribute("position", `0 ${height / 2} 0.01`);
  },
});
