import { mergeBufferGeometries } from "../utils/BufferGeometryUtils.js";

const AFRAME = global.AFRAME;
const THREE = global.THREE;

/* global AFRAME */
if (typeof AFRAME === "undefined") {
  throw new Error(
    "Component attempted to register before AFRAME was available."
  );
}

AFRAME.registerComponent("label", {
  schema: {
    tailHeight: { default: 5, min: 1 }, //the stick
    tailWidth: { default: 0.25, min: 0.1 }, //the stick
    height: { default: 1, min: 0 }, //the label
    width: { default: 1, min: 0 }, //the label
    text: { default: "Hello world!" }, //the label
    imageWidth: { default: 6 }, //the image
    imageHeight: { default: 4 }, //the image
    dotRadius: { default: 1 }, //the dot
    image: { default: "" },
    labelRotation: { default: 0 }, //if label is to be below horizon
  },

  init: function () {
    var data = this.data;

    this.lerpValues = {
      tailHeight: {
        target: this.data.tailHeight,
        actual: this.data.tailHeight,
      },
      labelScale: {
        target: 1.5, // *** default START size of resting label def = 1 ***
        actual: 1.5,
      },
      labelOpacity: {
        //fade in image (0) ****
        target: 0,
        actual: 0,
      },
      labelImageOpacity: {
        //fade in image (0) ****
        target: 0,
        actual: 0,
      },
      dotScale: {
        target: 1, //default size of resting dot (1) ****
        actual: 1,
        // https://threejs.org/docs/index.html?q=vector#api/en/math/Vector3.lerp
        alpha: 0.1, //see docs above (higher is faster)
      },
      topDotScale: {
        target: 1,
        actual: 1,
      },
      topDotOpacity: {
        target: 1,
        actual: 1,
      },
      tailOpacity: {
        target: 1,
        actual: 1,
      },
    };

    // Define materials
    this.dotMat = new THREE.MeshBasicMaterial({
      //the dot
      color: "rgb(133,255,181)",
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 1.0,
    });

    this.topDotMat = new THREE.MeshBasicMaterial({
      //the dot
      color: "white",
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 1.0,
      depthTest: false,
    });

    this.colliderDotMat = new THREE.MeshBasicMaterial({
      //the debug dot
      color: "#f30dc5",
      transparent: true,
      opacity: 0.0, // note  - Set to 0 for transparent / 0.3 for debug to see collision
    });

    this.labelMat = new THREE.MeshBasicMaterial({
      //the label
      color: "white",
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.9,
    });

    this.tailMat = new THREE.MeshBasicMaterial({
      //the label
      color: "white",
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.9,
    });

    this.textMat = new THREE.MeshBasicMaterial({
      //the label
      color: "black",
      transparent: true,
      opacity: 1.0,
    });

    const loadingManager =
      document.querySelector("a-assets").fileLoader.manager;
    const texture = new THREE.TextureLoader(loadingManager).load(data.image); //the image
    const alphaTexture = new THREE.TextureLoader(loadingManager).load(
      "/assets/images/alpha-map.jpg"
    ); //the image

    this.labelImageMat = new THREE.MeshBasicMaterial({
      map: texture,
      alphaMap: alphaTexture,
      transparent: true,
      opacity: 1.0,
    });

    // Setup child label mesh
    this.labelDotObject = document.createElement("a-entity");
    this.el.appendChild(this.labelDotObject);

    this.labelTopDotObject = document.createElement("a-entity");
    this.el.appendChild(this.labelTopDotObject);

    this.labelMeshObject = document.createElement("a-entity");
    this.el.appendChild(this.labelMeshObject);

    this.tailMeshObject = document.createElement("a-entity");
    this.el.appendChild(this.tailMeshObject);

    this.labelImageObject = document.createElement("a-entity");
    this.el.appendChild(this.labelImageObject);

    // troika-text
    this.labelTextObject = document.createElement("a-entity");
    this.labelMeshObject.appendChild(this.labelTextObject);

    this.regenerate();
  },
  tick: function () {
    // Calculate distance from camera direction
    if ((this.lastUpdateTime || 0) + 100 < Date.now()) {
      //change value for updates : 100 = default

      this.lastUpdateTime = Date.now();

      const camera = document.querySelector("[camera]").object3D;
      const object3D = this.el.object3D;

      const cameraPosition = camera.position;
      const cameraDirection = new THREE.Vector3();
      camera.getWorldDirection(cameraDirection);

      const ray = new THREE.Ray(cameraPosition, cameraDirection);

      const pointAt = new THREE.Vector3();
      ray.at(-100, pointAt);

      const worldPos = new THREE.Vector3();
      worldPos.setFromMatrixPosition(object3D.matrixWorld);

      worldPos.y = 0;
      pointAt.y = 0;

      const distance = worldPos.distanceTo(pointAt);

      // THIS IS THE RED ZONE VALUE
      //changed to 5 from 3 for "stickier" popups - then to 4 for a compromise
      if (distance < 4) {
        this.lerpValues.labelScale.target = 2;
        this.lerpValues.labelOpacity.target = 1;
        this.lerpValues.labelImageOpacity.target = 1;

        this.lerpValues.tailOpacity.target = 1;

        this.lerpValues.dotScale.target = 1.5;

        this.lerpValues.topDotScale.target = 0;
        this.lerpValues.topDotOpacity.target = 0;
      } else if (distance < 10) {
        this.lerpValues.labelScale.target = 1.5;
        this.lerpValues.labelImageOpacity.target = 0;
        this.lerpValues.labelOpacity.target = 0;

        this.lerpValues.tailOpacity.target = 1;

        this.lerpValues.dotScale.target = 1.0;

        this.lerpValues.topDotScale.target = 2;
      } else if (distance < 15) {
        this.lerpValues.labelScale.target = 1;
        this.lerpValues.labelOpacity.target = 0;

        this.lerpValues.tailOpacity.target = 1;

        this.lerpValues.dotScale.target = 0.75;

        this.lerpValues.topDotScale.target = 0.5;
      } else {
        this.lerpValues.labelOpacity.target = 0;
        this.lerpValues.labelScale.target = 1;
        this.lerpValues.labelImageOpacity.target = 0;

        this.lerpValues.tailOpacity.target = 0;

        this.lerpValues.dotScale.target = 0.5;

        this.lerpValues.topDotScale.target = 0;
      }
      //note - we could add more distance steps - at possible cost to speed of app
    }

    Object.values(this.lerpValues).forEach((entry) => {
      const actual = new THREE.Vector3(entry.actual, 0, 0);
      const target = new THREE.Vector3(entry.target, 0, 0);
      // todo -tweak this value for smoother transitions
      actual.lerp(target, entry.alpha || 0.05); //lerp alpha amount default = 0.15
      entry.actual = actual.x;
    });

    if ((this.lastLerpUpdateTime || 0) + 5 < Date.now()) {
      //change value for updates : 100 = default

      this.lastLerpUpdateTime = Date.now();
      const prevLerp = this.lerpSerialized || "";
      const currentLerp = JSON.stringify(this.lerpValues);

      if (prevLerp !== currentLerp) {
        this.updateLerpValues();
        this.lerpSerialized = currentLerp;
      }
    }
  },
  updateLerpValues: function () {
    const text = this.data.text;
    const height = this.data.height;
    const imageHeight = this.data.imageHeight;
    const labelHeight = this.lerpValues.tailHeight.actual + height;
    const labelRotation = this.data.labelRotation;
    const labelScale = this.lerpValues.labelScale.actual;

    const dotScale = this.lerpValues.dotScale.actual;
    this.labelDotObject.object3D.scale.setScalar(dotScale);

    const topDotScale = this.lerpValues.topDotScale.actual;
    this.labelTopDotObject.object3D.scale.setScalar(topDotScale);
    this.labelTopDotObject.object3D.position.set(
      0,
      this.lerpValues.tailHeight.actual * labelScale,
      -0.05
    );

    // Label part
    this.labelMeshObject.object3D.scale.setScalar(labelScale);

    this.labelImageObject.object3D.position.set(
      0,
      labelHeight * labelScale +
        (imageHeight * labelScale) / 2 -
        (labelHeight * labelScale) / 4,
      -0.05
    );
    this.labelImageObject.object3D.scale.setScalar(labelScale);
    this.labelImageObject.object3D.rotation.set(
      0,
      0,
      THREE.MathUtils.degToRad(labelRotation)
    );

    this.labelImageMat.opacity = this.lerpValues.labelImageOpacity.actual;

    // Tail
    this.tailMat.opacity = this.lerpValues.tailOpacity.actual;
    this.tailMeshObject.object3D.scale.setScalar(labelScale);

    const labelOpacity = this.lerpValues.labelOpacity.actual;
    this.labelTextObject.object3D.traverse(function (node) {
      if (node.isMesh) {
        node.material.opacity = labelOpacity;
        node.material.transparent = labelOpacity < 1.0;
        node.material.needsUpdate = true;
      }
    });

    this.labelMeshObject.object3D.traverse(function (node) {
      if (node.isMesh) {
        node.material.opacity = labelOpacity;
        node.material.transparent = labelOpacity < 1.0;
        node.material.needsUpdate = true;
      }
    });

    // End label part
  },
  generateLabelGeometry: function () {
    const width = this.data.width;
    const height = this.data.height;
    const tailHeight = this.lerpValues.tailHeight.actual;

    const labelLeftGeometry = new THREE.CircleGeometry(
      height / 2,
      32,
      Math.PI / 2,
      Math.PI
    );
    const leftMat = new THREE.Matrix4();
    leftMat.setPosition(-width / 2, 0, 0);
    labelLeftGeometry.applyMatrix4(leftMat);

    const labelRightGeometry = new THREE.CircleGeometry(
      height / 2,
      32,
      -Math.PI / 2,
      Math.PI
    );
    const rightMat = new THREE.Matrix4();
    rightMat.setPosition(width / 2, 0, 0);
    labelRightGeometry.applyMatrix4(rightMat);

    const labelCenterGeometry = new THREE.PlaneGeometry(width, height);

    const labelGeometry = mergeBufferGeometries([
      labelLeftGeometry,
      labelCenterGeometry,
      labelRightGeometry,
    ]);
    const labelGeometryMatrix = new THREE.Matrix4();

    labelGeometryMatrix.setPosition(0, tailHeight + height / 2, 0);
    labelGeometry.applyMatrix4(labelGeometryMatrix);
    return labelGeometry;
  },
  generateTailGeometry: function () {
    const tailHeight = this.lerpValues.tailHeight.actual;
    const tailGeometry = new THREE.PlaneGeometry(0.2, tailHeight);
    const tailGeometryMatrix = new THREE.Matrix4();
    tailGeometryMatrix.setPosition(0, tailHeight / 2, -0.001);
    tailGeometry.applyMatrix4(tailGeometryMatrix);
    return tailGeometry;
  },
  regenerate: function () {
    const text = this.data.text;
    const width = this.data.width;
    const height = this.data.height;
    const imageWidth = this.data.imageWidth;
    const imageHeight = this.data.imageHeight;
    const dotRadius = this.data.dotRadius;
    const labelHeight = this.lerpValues.tailHeight.actual + height;
    const labelRotation = this.data.labelRotation;

    // Label part
    const labelScale = this.lerpValues.labelScale.actual;
    const labelGeometry = this.generateLabelGeometry();
    const childMesh = new THREE.Mesh(labelGeometry, this.labelMat);
    this.labelMeshObject.setObject3D("mesh", childMesh);

    const tailGeometry = this.generateTailGeometry();
    const tailMesh = new THREE.Mesh(tailGeometry, this.tailMat);
    this.tailMeshObject.setObject3D("mesh", tailMesh);

    const imageMeshGeometry = new THREE.PlaneGeometry(imageWidth, imageHeight);
    const imageMesh = new THREE.Mesh(imageMeshGeometry, this.labelImageMat);
    this.labelImageObject.setObject3D("mesh", imageMesh);
    // End label part

    const topDotGeometry = new THREE.CircleGeometry(dotRadius, 32);
    const topDotMesh = new THREE.Mesh(topDotGeometry, this.topDotMat);
    this.labelTopDotObject.setObject3D("mesh", topDotMesh);

    // Dot geometry
    const dotGeometry = new THREE.CircleGeometry(dotRadius, 32);
    const dotMesh = new THREE.Mesh(dotGeometry, this.dotMat);
    this.labelDotObject.setObject3D("mesh", dotMesh);

    // const colliderDotGeometry2 = new THREE.CircleGeometry( dotRadius * 32, 32);//todo note - how big to make the intersector for the pre collision grow highlight
    // const colliderDotMesh2 = new THREE.Mesh(colliderDotGeometry2, this.colliderDotMat);
    // this.labelColliderObject.setObject3D('mesh', colliderDotMesh2);

    // const colliderDotGeometry1 = new THREE.CircleGeometry( dotRadius * 12, 32);//todo note - how big to make the intersector for the popup collision ****
    // const colliderDotMesh1 = new THREE.Mesh(colliderDotGeometry1, this.colliderDotMat);
    // this.el.setObject3D('mesh', colliderDotMesh1);

    // Update text
    this.labelTextObject.setAttribute(
      "troika-text",
      `font: assets/fonts/conduitITCmediumRegularFont.ttf; fontSize: 1.3; color: black; value: ${text}`
    );
    this.labelTextObject.setAttribute(
      "position",
      `0 ${this.lerpValues.tailHeight.actual + height / 2} 0.01`
    );
  },
});
